/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-debugger */
import React, { useEffect, useState } from 'react';
import '../../../Assets/css/HomeScreen.css';
import '../../../Assets/css/Userfeed.css';
import '../../../Assets/css/responsive.css';
import { Button, Chip, Backdrop, CircularProgress, Grid, Typography } from '@mui/material';
import useAxiosPrivate from '../../../Hooks/useAxioPrivate';
import Sidebar from '../SideNavBar';
import CustomSnackbar from '../../../common/Snackbar';
import Navbar from '../Navbar';
import { UserInfo, filterData } from '../../Interface/UserInterface/IUser';
import BookingRequestDialog from './BookingRequestDialogProps';
import CardDisplay from '../CardDisplay';
import AddListDialog from '../AddListDialog';
import CreateListDialog from '../CreateListDialog';
import { useNavigate } from 'react-router-dom';
import { BulkHireDialog } from './BulkHireDialog';
import { MessageDialog } from '../MessageDialog';
import io from 'socket.io-client';
import { connect } from 'react-redux';

const UserFeed: React.FC = (props: any) => {
  const privateAxios = useAxiosPrivate();
  const navigate = useNavigate();

  const [searchValues, setSearchValues] = useState<string>('');
  const [cardData, setCardData] = useState<any>({ newTalent: [], popularTalent: [] });
  const [selectArtist, setSelectArtist] = useState<any[]>([]);
  const [selectCard, setSelectCard] = useState(false);
  const [allCards, setAllCards] = useState<any[]>([]);
  const [isBookingRequestDialogOpen, setBookingRequestDialogOpen] = useState(false);
  const [bookingRequestData, setBookingRequestData] = useState<any>(null);
  const [socket, setSocket] = useState<any | null>(null);
  const [selectedUser, setSelectedUser] = useState<UserInfo | null>(null);
  const [skillsData, setSkillsData] = useState<any[]>([]); // Adjust the type based on your data structure
  const [toolsData, setToolsData] = useState<any[]>([]);
  const [categoryData, setCategoryData] = useState<any[]>([]);
  const [getListDatas, setListDatas] = useState([]);
  const [isNewListDialogOpen, setNewListDialogOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [toast, setToast] = React.useState({ message: '', type: 'success' });
  const [filterStructure, setFilterStructure] = useState<filterData>({
    categoryKey: '',
    categoryskillsKey: '',
    categorytoolsKey: '',
    toolsKey: [],
    skillsKey: [],
    country: '',
    state: '',
    language: '',
    skillLabels: [],
    toolLabels: [],
    categoryLabels: [],
  });
  const [loading, setLoading] = useState(false);
  const [openBulkDialog, setOpenBulkDialog] = useState(false);
  const [bulkStatus, setBulkStatus] = useState({});
  const [refresh, setRefresh] = useState<boolean>(false);
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [resetMessage, setResetMessage] = useState(false);
  const [onlineUsers, setOnlineUsers] = useState<any | null>(null);
  const [multiRoster, setMultiRoster] = useState(false);
  const [openNewList, setOpenNewList] = useState(false);

  useEffect(() => {
    userFeed();
    getList();
  }, [searchValues, filterStructure]);

  let search: any;

  useEffect(() => {
    search = localStorage.getItem('searchValue');

    if (search) {
      setSearchValues(search);
    }
  }, []);

  useEffect(() => {
    const elementsWithPClass = document.querySelectorAll('#card-top');
    elementsWithPClass.forEach((element) => {
      const rootDiv = element.closest('.css-1y36zv6');
      if (rootDiv) {
        rootDiv.classList.add('centerclass');
      }
    });
  }, []);

  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_SOCKET_API_URL);
    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (socket === null) return;
    socket.emit('addNewUser', props.id);
    socket.on('getOnlineUsers', (res: any) => {
      setOnlineUsers(res);
    });

    return () => {
      socket.off('getOnlineUsers');
    };
  }, [socket]);

  const handleSearchValuesChange = (newSearchValues: string) => {
    setSearchValues(newSearchValues);
  };

  const { skillLabels, toolLabels, categoryLabels, ...filterData } = filterStructure || {};

  const userFeed = () => {
    setLoading(true);
    const feedData = privateAxios.post(`/dashboard/getfeed?search=${searchValues}`, filterData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    feedData
      .then((response: any) => {
        if (response.status === 200) {
          setCardData({
            newTalent: response.data.data.newTalent,
            popularTalent: response.data.data.popularTalent,
          });
          setLoading(false);
          localStorage.removeItem('searchValue');
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
        setLoading(false);
      });
  };

  const handleEyeIconClick = (user: any) => {
    setSelectedUser(user);
    navigate('/user-details', { state: { showUser: true, id: user.id } });
  };

  const handleListIconClick = (user: any) => {
    setSelectedUser(user);
    // navigate('/user-details', { state: { showUser: true } });
  };

  const handleCloseDialog = () => {
    setSelectedUser(null);
    setRefresh(false);
  };

  const addArtistList = (newValue: any) => {
    const addArtist = {
      listKey: newValue,
      rosterId: selectedUser?.id,
    };

    const addArtistToList = privateAxios.post(`dashboard/addlist`, addArtist, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    addArtistToList
      .then((response: any) => {
        if (response.status === 200) {
          setToast({ message: response.data.message.value, type: 'success' });
          handleClick();
          handleCloseDialog();
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const handleCategoryChipDelete = (category: string) => {
    setFilterStructure((prevFilterStructure: any) => {
      const updatedCategories = prevFilterStructure.categoryLabels?.filter(
        (prevCategory: string) => prevCategory !== category
      );

      const updatedCategoryKeys = categoryData
        ?.filter((cat: any) => updatedCategories?.includes(cat.label))
        .map((cat: any) => cat.key);

      const newFilterStructure = {
        ...prevFilterStructure,
        categoryLabels: updatedCategories,
        categoryKey: updatedCategoryKeys,
      };
      updateFilterStructure(newFilterStructure);

      return newFilterStructure;
    });
  };

  const handleSkillChipDelete = (skill: string) => {
    setFilterStructure((prevFilterStructure: any) => {
      const updatedSkills = prevFilterStructure.skillLabels.filter((prevSkill: string) => prevSkill !== skill);

      const updatedSkillsKey = skillsData
        .flatMap((category: any) => category.skills)
        .filter((s: any) => updatedSkills.includes(s.label))
        .map((s: any) => s.key);

      const updatedCategories = skillsData
        .filter((category: any) => category.skills.some((s: any) => updatedSkills.includes(s.label)))
        .map((category: any) => category.category.key);

      const newFilterStructure = {
        ...prevFilterStructure,
        skillLabels: updatedSkills,
        skillsKey: updatedSkillsKey,
        categoryskillsKey: updatedCategories,
      };

      updateFilterStructure(newFilterStructure);

      return newFilterStructure;
    });
  };

  const handleToolChipDelete = (tool: string) => {
    setFilterStructure((prevFilterStructure: any) => {
      const updatedTools = prevFilterStructure.toolLabels?.filter((prevTool: string) => prevTool !== tool);

      const updatedToolsKeys = toolsData?.flatMap((category: any) =>
        category.tools.filter((tool: any) => updatedTools?.includes(tool.label)).map((tool: any) => tool.key)
      );

      const updatedCategories = toolsData
        ?.filter(
          (category: any) =>
            category.tools.some((tool: any) => updatedTools?.includes(tool.label)) && category.tools.length > 0
        )
        .map((category: any) => category.category.key);

      const finalCategories = updatedCategories?.filter((categoryKey: string) =>
        toolsData
          ?.find((category: any) => category.category.key === categoryKey)
          ?.tools.some((tool: any) => updatedTools?.some((updatedTool: any) => updatedTool === tool.label))
      );

      const newFilterStructure = {
        ...prevFilterStructure,
        toolLabels: updatedTools,
        toolsKey: updatedToolsKeys,
        categorytoolsKey: finalCategories,
      };
      updateFilterStructure(newFilterStructure);

      return newFilterStructure;
    });
  };

  const handleLocationChipDelete = (label: string) => {
    const newFilterStructure = { ...filterStructure };

    switch (label) {
      case 'country':
        newFilterStructure.country = '';
        break;
      case 'state':
        newFilterStructure.state = '';
        break;
      case 'language':
        newFilterStructure.language = '';
        break;

      default:
        break;
    }

    updateFilterStructure(newFilterStructure);
  };

  const hasFilters = (filterStructure: {
    categoryLabels: string | any[];
    skillLabels: string | any[];
    toolLabels: string | any[];
    country: any;
    state: any;
    language: any;
  }) => {
    return (
      filterStructure?.categoryLabels?.length > 0 ||
      filterStructure?.skillLabels?.length > 0 ||
      filterStructure?.toolLabels?.length > 0 ||
      filterStructure?.country ||
      filterStructure?.state ||
      filterStructure?.language
    );
  };

  const updateFilterStructure = (newStructure: any) => {
    setFilterStructure(newStructure);
  };

  const handleSkillsDataChange = (newSkillsData: any[]) => {
    setSkillsData(newSkillsData);
  };

  const handleToolsDataChange = (newToolsData: any[]) => {
    setToolsData(newToolsData);
  };

  const handleCategoryChange = (newCategoryData: any[]) => {
    setCategoryData(newCategoryData);
  };

  const getList = () => {
    const getListData = privateAxios.get(`dashboard/getlist`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    getListData
      .then((response: any) => {
        if (response.status === 200) {
          setListDatas(response.data.data || []);
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const handleAddNewListClick = () => {
    setNewListDialogOpen(true);
    setRefresh(false);
  };

  const handleCloseNewListDialog = () => {
    setNewListDialogOpen(false);
    getList();
    setRefresh(true);
  };

  const handleClick = () => {
    setOpen(true);

    setTimeout(() => {
      handleClose();
    }, 2000);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectArtist = () => {
    setSelectCard(true);
    const allCards: any[] = [...cardData.newTalent, ...cardData.popularTalent];
    setAllCards(allCards);
    // setSelectArtist(allCards);
  };

  const handleCardClick = (emailId: string, id: number, isRosterAdded: boolean) => {
    const updatedSelectedCards = [...selectArtist];
    const index = updatedSelectedCards.findIndex((card) => card.email === emailId);

    if (index === -1) {
      updatedSelectedCards.push({ email: emailId, id: id, isRosterAdded: isRosterAdded });
    } else {
      updatedSelectedCards.splice(index, 1);
    }

    setSelectArtist(updatedSelectedCards);
  };

  const handleAddtoRoster = (list: any) => {
    const filetrAddedArtist: any = [];
    selectArtist.filter((artist) => {
      if (!artist.isRosterAdded) {
        filetrAddedArtist.push(artist.id);
      }
    });
    const emails = {
      listKey: list,
      rosterIds: filetrAddedArtist,
      // email: selectArtist.map((artist) => artist.email),
    };

    const addRoster = privateAxios.post(`dashboard/addmultilist`, emails, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    addRoster
      .then((response: any) => {
        if (response.status === 200) {
          setToast({ message: response.data.message.value, type: 'success' });
          setSelectArtist([]);
          setSelectCard(false);
          setMultiRoster(false);
          userFeed();
          handleClick();
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const handleAddMultiList = () => {
    setOpenNewList(true);
  };

  const handleCloseEditDialog = () => {
    setRefresh(false);
    setOpenNewList(false);
  };

  const handleSendBookingRequest = (data: any) => {
    setBookingRequestData(data);
    setBookingRequestDialogOpen(true);
  };

  const handleBookingRequestDialogClose = () => {
    setBookingRequestDialogOpen(false);
    setSelectArtist([]);
    setSelectCard(false);
  };

  const handleSendBookingRequestDialog = (sendData: any) => {
    const { bookingId, ...restData } = sendData;

    if (sendData.bookingIds.length > 0) {
      const sendHiringRequest = privateAxios.post(`dashboard/bulkhire`, restData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      sendHiringRequest
        .then((response: any) => {
          if (response.status === 200) {
            setToast({ message: response.data.message.value, type: 'success' });
            // setBookingRequestDialogOpen(false);
            // setSelectCard(false);
            setOpenBulkDialog(true);
            setBulkStatus(response.data.data);
            setSelectArtist([]);
            handleClick();
            return;
          }
        })
        .catch((error: any) => {
          console.error(error);
          setToast({ message: error.response.data.message.value, type: 'error' });
          handleClick();
          return;
        });
    } else {
      const { bookingIds, ...singleHire } = sendData;
      const sendHireRequest = privateAxios.post(`dashboard/hire`, singleHire, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      sendHireRequest
        .then((response: any) => {
          if (response.status === 200) {
            setToast({ message: response.data.message.value, type: 'success' });
            setSelectCard(false);
            setBookingRequestDialogOpen(false);
            setSelectArtist([]);
            handleClick();
          }
        })
        .catch((error: any) => {
          console.error(error);
          setToast({ message: error.response.data.message.value, type: 'error' });
          handleClick();
        });
    }
  };

  const closeBulkStatus = () => {
    setOpenBulkDialog(false);
    setBookingRequestDialogOpen(false);
    setSelectCard(false);
  };

  const handleMessage = () => {
    setOpenMessageDialog(true);
  };

  const handleMessageClose = () => {
    setOpenMessageDialog(false);
  };

  const handleBulkMessageSubmit = (message: string) => {
    const response = privateAxios.post(
      `chat/bulkchat`,
      JSON.stringify({
        receiverIds: selectArtist.map((artist) => artist.id),
        message: message,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    response
      .then((response: any) => {
        if (response.status === 200) {
          setToast({ message: response.data.message.value, type: 'success' });
          handleClick();
          setResetMessage(true);
          setOpenMessageDialog(false);
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });

    if (socket) {
      const messageData = {
        content: message,
        sender_id: props.id,
        recipientIds: selectArtist.map((artist) => artist.id),
      };
      socket.emit('sendMessage', messageData);
    } else {
      console.error('Socket is not available.');
    }
  };

  return (
    <>
      <Navbar onSearchValuesChange={handleSearchValuesChange} />
      <Grid id="card-top">
        <Grid className="talentBody" sx={{ backgroundColor: '#f7f8f8' }} container>
          <Grid item sm={1}>
            <Sidebar
              filterStructure={filterStructure}
              updateFilterStructure={updateFilterStructure}
              onChangeSkillData={handleSkillsDataChange}
              onChangeToolData={handleToolsDataChange}
              onChangeCategoryData={handleCategoryChange}
            />
          </Grid>

          {selectCard ? (
            <Grid item sm={11} sx={{ backgroundColor: '#f7f8f8', mt: '80px' }}>
              <div className="right-dash">
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ marginBottom: '10px', backgroundColor: '#fff', padding: '16px', borderRadius: '12px' }}>
                  <Grid>
                    <Typography variant="h6" gutterBottom sx={{ fontFamily: "'Onest', sans-serif !important" }}>
                      {`${selectArtist.length} Artist Selected`}
                    </Typography>
                  </Grid>
                  <Grid className="button-media">
                    {/* {multiRoster ? (
                      <Button
                        variant="contained"
                        color="primary"
                        className="common-button-green"
                        sx={{
                          mr: '8px',
                          opacity: selectArtist.length === 0 ? 0.5 : '',
                          color: selectArtist.length === 0 ? '#000 !important' : '#fff !important',
                          backgroundColor: selectArtist.length === 0 ? '#667085 !important' : '#2cb512 !important',
                        }}
                        disabled={selectArtist.length === 0}
                        // onClick={handleAddtoRoster}>
                        onClick={handleAddMultiList}>
                        Add to roster
                      </Button>
                    ) : (
                      <> */}
                    <Button
                      variant="contained"
                      color="primary"
                      className="common-button-green roster-btn"
                      sx={{
                        ml: '8px',
                        opacity: selectArtist.length === 0 ? 0.5 : '',
                        color: selectArtist.length === 0 ? '#000 !important' : '#fff !important',
                        backgroundColor: selectArtist.length === 0 ? '#667085 !important' : '#2cb512 !important',
                      }}
                      // disabled={selectArtist.length === 0}setMultiRoster
                      // onClick={handleAddtoRoster}>
                      onClick={() => {
                        setMultiRoster(true);
                        handleSelectArtist();
                        setOpenNewList(true);
                      }}>
                      Add To Roster
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      className="common-button-green"
                      sx={{
                        ml: '15px',
                        opacity: selectArtist.length === 0 ? 0.5 : '',
                        color: selectArtist.length === 0 ? '#000 !important' : '#fff !important',
                        backgroundColor: selectArtist.length === 0 ? '#667085 !important' : '#2cb512 !important',
                      }}
                      disabled={selectArtist.length === 0}
                      onClick={handleMessage}>
                      Message
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      className="common-button-green"
                      sx={{
                        width: '200px',
                        m: '0px 15px',
                        opacity: selectArtist.length === 0 ? 0.5 : '',
                        color: selectArtist.length === 0 ? '#000 !important' : '#fff !important',
                        backgroundColor: selectArtist.length === 0 ? '#667085 !important' : '#2cb512 !important',
                      }}
                      disabled={selectArtist.length === 0}
                      onClick={() => handleSendBookingRequest(selectArtist)}>
                      Send booking request
                    </Button>
                    {/* </>
                    )} */}

                    <Button
                      variant="contained"
                      color="primary"
                      className="common-button-white"
                      onClick={() => {
                        setSelectCard(false), setSelectArtist([]), setMultiRoster(false);
                      }}>
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
                <Grid>
                  <CardDisplay
                    cardData={allCards}
                    eyeIcon={handleEyeIconClick}
                    listIcon={handleListIconClick}
                    selectedArtist={selectArtist}
                    onCardClick={handleCardClick}
                    type={multiRoster ? 'multiSelect' : 'select'}
                  />
                </Grid>
              </div>
              <AddListDialog
                openList={openNewList}
                // selectedUser={selectedUser}
                handleCloseDialog={handleCloseEditDialog}
                multiAdd={true}
                // selectedListVal={selectedValue}
                // receiverId={receiverId}
                addArtistList={handleAddtoRoster}
                handleAddNewListClick={handleAddNewListClick}
                refresh={refresh}
              />
              <CreateListDialog
                isNewListDialogOpen={isNewListDialogOpen}
                handleCloseNewListDialog={handleCloseNewListDialog}
              />
              <BookingRequestDialog
                open={isBookingRequestDialogOpen}
                onCloseDialog={handleBookingRequestDialogClose}
                onSendBookingRequest={handleSendBookingRequestDialog}
                data={bookingRequestData}
                selectedArtist={selectArtist}
              />
              <BulkHireDialog openBulkDialog={openBulkDialog} onCloseDialog={closeBulkStatus} bulkStatus={bulkStatus} />
              <MessageDialog
                openMessageDialog={openMessageDialog}
                handleClose={handleMessageClose}
                user={null}
                handleMessageSubmit={handleBulkMessageSubmit}
                resetMessage={resetMessage}
              />
            </Grid>
          ) : searchValues !== '' ||
            filterStructure.categoryLabels.length > 0 ||
            filterStructure.toolLabels.length > 0 ||
            filterStructure.skillLabels.length > 0 ||
            filterStructure.language !== '' ||
            filterStructure.country !== '' ||
            filterStructure.state !== '' ? (
            <>
              <Grid item sm={11} sx={{ backgroundColor: '#f7f8f8', mt: '80px' }}>
                <div>
                  <div className="right-dash">
                    <div className="col-md-12">
                      <Typography component={'span'} sx={{ mr: '15px', fontFamily: "'Onest', sans-serif" }}>
                        <span style={{ fontWeight: 600, fontSize: '34px' }}>Your Results</span>
                      </Typography>
                      {filterStructure && hasFilters(filterStructure) ? (
                        <>
                          <div>
                            {filterStructure?.categoryLabels?.map((category: any, index: number) => (
                              <Chip
                                key={index}
                                label={category}
                                onDelete={() => handleCategoryChipDelete(category)}
                                sx={{
                                  marginRight: '8px',
                                  marginBottom: '8px',
                                  fontFamily: "'Onest', sans-serif !important",
                                }}
                              />
                            ))}
                            {filterStructure?.skillLabels?.map((skill: any, index: number) => (
                              <Chip
                                key={index}
                                label={skill}
                                onDelete={() => handleSkillChipDelete(skill)}
                                sx={{
                                  marginRight: '8px',
                                  marginBottom: '8px',
                                  fontFamily: "'Onest', sans-serif !important",
                                }}
                              />
                            ))}
                            {filterStructure.toolLabels?.map((tool: any, index: number) => (
                              <Chip
                                key={index}
                                label={tool}
                                onDelete={() => handleToolChipDelete(tool)}
                                sx={{
                                  marginRight: '8px',
                                  marginBottom: '8px',
                                  fontFamily: "'Onest', sans-serif !important",
                                }}
                              />
                            ))}
                            {filterStructure?.country && (
                              <Chip
                                label={filterStructure.country}
                                onDelete={() => handleLocationChipDelete('country')}
                                sx={{
                                  marginRight: '8px',
                                  marginBottom: '8px',
                                  fontFamily: "'Onest', sans-serif !important",
                                }}
                              />
                            )}
                            {filterStructure?.state && (
                              <Chip
                                label={filterStructure.state}
                                onDelete={() => handleLocationChipDelete('state')}
                                sx={{
                                  marginRight: '8px',
                                  marginBottom: '8px',
                                  fontFamily: "'Onest', sans-serif !important",
                                }}
                              />
                            )}
                            {filterStructure?.language && (
                              <Chip
                                label={filterStructure.language}
                                onDelete={() => handleLocationChipDelete('language')}
                                sx={{
                                  marginRight: '8px',
                                  marginBottom: '8px',
                                  fontFamily: "'Onest', sans-serif !important",
                                }}
                              />
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <Typography className="dash-title" sx={{ fontFamily: "'Onest', sans-serif !important" }}>
                            <span style={{ fontWeight: 600, fontSize: '24px' }}>New Talent</span>
                          </Typography>
                        </>
                      )}
                    </div>
                    {loading && (
                      <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                        onClick={() => setLoading(false)}>
                        <CircularProgress
                          color="success"
                          // style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                        />
                      </Backdrop>
                    )}
                    {!loading && (
                      <Grid>
                        <CardDisplay
                          cardData={cardData.newTalent}
                          eyeIcon={handleEyeIconClick}
                          listIcon={handleListIconClick}
                        />
                      </Grid>
                    )}
                    {!loading && cardData.popularTalent.length > 0 && (
                      <>
                        <Typography className="dash-title" sx={{ fontFamily: "'Onest', sans-serif !important" }}>
                          <span style={{ fontWeight: 600, fontSize: '24px' }}>Popular Talent</span>
                        </Typography>
                        <CardDisplay
                          cardData={cardData.popularTalent}
                          eyeIcon={handleEyeIconClick}
                          listIcon={handleListIconClick}
                        />
                      </>
                    )}
                  </div>
                  <AddListDialog
                    selectedUser={selectedUser}
                    handleCloseDialog={handleCloseDialog}
                    getListDatas={getListDatas}
                    addArtistList={addArtistList}
                    handleAddNewListClick={handleAddNewListClick}
                    refresh={refresh}
                  />
                  <CreateListDialog
                    isNewListDialogOpen={isNewListDialogOpen}
                    handleCloseNewListDialog={handleCloseNewListDialog}
                  />
                  <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
                </div>
              </Grid>
            </>
          ) : (
            <>
              {loading && (
                <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={loading}
                  onClick={() => setLoading(false)}>
                  <CircularProgress
                    color="success"
                    // style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                  />
                </Backdrop>
              )}
              {!loading && (
                <Grid item sm={11} sx={{ backgroundColor: '#f7f8f8', mt: '80px' }}>
                  <div>
                    <div className="right-dash">
                      <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography
                          className="dash-title"
                          sx={{ fontFamily: "'Onest', sans-serif !important", marginTop: '7px' }}>
                          <span style={{ fontWeight: 600, fontSize: '24px' }}>New Talent</span>
                        </Typography>
                        <Grid>
                          <Button
                            variant="contained"
                            color="primary"
                            className="common-button-green"
                            onClick={handleSelectArtist}>
                            Select Artist
                          </Button>
                        </Grid>
                      </Grid>

                      <CardDisplay
                        cardData={cardData.newTalent}
                        eyeIcon={handleEyeIconClick}
                        listIcon={handleListIconClick}
                      />
                      {cardData.popularTalent.length > 0 && (
                        <>
                          <Typography className="dash-title" sx={{ fontFamily: "'Onest', sans-serif !important" }}>
                            <span style={{ fontWeight: 600, fontSize: '24px' }}>Popular Talent</span>
                          </Typography>
                          <CardDisplay
                            cardData={cardData.popularTalent}
                            eyeIcon={handleEyeIconClick}
                            listIcon={handleListIconClick}
                          />
                        </>
                      )}
                    </div>

                    <AddListDialog
                      selectedUser={selectedUser}
                      handleCloseDialog={handleCloseDialog}
                      getListDatas={getListDatas}
                      addArtistList={addArtistList}
                      handleAddNewListClick={handleAddNewListClick}
                      refresh={refresh}
                    />
                    <CreateListDialog
                      isNewListDialogOpen={isNewListDialogOpen}
                      handleCloseNewListDialog={handleCloseNewListDialog}
                    />
                  </div>
                </Grid>
              )}
            </>
          )}
          <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
        </Grid>
      </Grid>
    </>
  );
};

function mapDispatchToProps(state: any) {
  return {
    id: state.id,
  };
}

export default connect(mapDispatchToProps)(UserFeed);
