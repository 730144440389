/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  // Dialog,
  // DialogTitle,
  // DialogContent,
  // DialogActions,
  TextField,
  Grid,
  Paper,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
// import Stripe from '../../../Assets/Images/Stripe.png';
// import creditdebitcard from '../../../Assets/Images/creditdebitcard.png';
import paypal from '../../../Assets/Images/paypal.png';
import cards from '../../../Assets/Images/cards.svg';
import useAxioPrivate from '../../../Hooks/useAxioPrivate';
import { useNavigate } from 'react-router-dom';
import '../../../Assets/css/ChoosePayment.css';
import CustomSnackbar from '../../../common/Snackbar';
import {
  backHandle,
  customerID,
  isLoggedIn,
  userAccessToken,
  userDC,
  userDisElgble,
  userId,
  userPM,
  userRefreshToken,
  userStepStatus,
  userType,
} from '../../../reducer/action';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../reducer/store';
import { connect } from 'react-redux';

const ChoosePayment = (props: any) => {
  const privateAxios = useAxioPrivate();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [selectedPayment, setSelectedPayment] = useState('stripe');
  // const [openChoose, setOpenChoose] = useState(true);
  const [toast, setToast] = React.useState({ message: '', type: 'success' });
  const [open, setOpen] = React.useState(false);
  const [discount, setDiscount] = useState('');
  const [result, setResult] = useState<any>({
    discountEligible: false,
    isPermanentDiscount: false,
    successMessage: '',
    errorMessage: '',
  });
  const [selectedValue, setSelectedValue] = useState('yes');
  const [show, setShow] = useState(false);

  const handleClick = () => {
    setOpen(true);
    setTimeout(() => {
      handleClose();
    }, 2000);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handlePaymentChange = (event: any) => {
    setSelectedPayment(event.target.value);
    setDiscount('');
    setResult({
      errorMessage: '',
    });
  };

  const handlePaymentSubmit = () => {
    // if (props.status === 'Registration' && selectedValue === 'yes') {
    //   if (discount === '') {
    //     setResult({
    //       errorMessage: 'Discount code is required',
    //     });
    //     return;
    //   }
    // } else {
    if (selectedPayment === 'stripe') {
      !props.CID ? navigate('/stripe/add') : navigate('/cardDetails');
    }
    if (selectedPayment === 'paypal') {
      const paypalPayment = privateAxios.post(`/payment/paypal/create`);
      paypalPayment
        .then((response: any) => {
          if (response.data.status === true) {
            const url = response.data.data;
            window.location.href = url;
          }
        })
        .catch((error: any) => {
          setToast({ message: error.response.data.message.value, type: 'error' });
          handleClick();
        });
    }
    if (selectedPayment === 'discount') {
      if (discount === '') {
        setResult({
          errorMessage: 'Discount code is required',
        });
        return;
      }
      const withDiscountCode = privateAxios.post(`/discount/withdiscount`);
      withDiscountCode
        .then((response: any) => {
          console.log('respppp', response.data.data.userStatus);

          setToast({ message: response.data.message.value, type: 'success' });
          dispatch(userStepStatus({ status: response.data.data.userStatus }));
          // if (props.userType) {
          //   navigate('/premium/sign-up');
          // } else {
          dispatch(userType(true));
          navigate('/');
          // }
        })
        .catch((error: any) => {
          setToast({ message: error.response.data.message.value, type: 'error' });
          handleClick();
        });
    }
    // }
  };

  const handleRadioChange = (event: any) => {
    const value = event.target.value;
    setSelectedValue(value);

    if (value === 'no') {
      setDiscount('');
    }

    setResult({
      errorMessage: '',
    });
  };

  const onCloseChoose = () => {
    // setOpenChoose(false);
    if (props.status === 'Registration') {
      navigate('/premium/sign-up');
    } else if (props.status === 'Active') {
      navigate('/account-dashboard');
    } else {
      dispatch(isLoggedIn(false));
      dispatch(backHandle(false));
      dispatch(userId(''));
      dispatch(customerID(''));
      dispatch(userStepStatus({ status: '' }));
      dispatch(userType(false));
      dispatch(userPM(false));
      dispatch(userAccessToken({ accessToken: '' }));
      dispatch(userRefreshToken({ refreshToken: '' }));
      dispatch(userDC(false));
      dispatch(userDisElgble(false));
      // dispatch(logOut());
      localStorage.removeItem('center');
      localStorage.clear();
      navigate('/login');
    }
  };

  const handleDiscountCode = () => {
    if (discount.length === 10) {
      if (discount.startsWith('PER-')) {
        permanentUser();
        return;
      } else {
        const discountCode = privateAxios.get(`plan/checkCoupon?promoCode=${discount}&isPlan=${true}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        discountCode
          .then((response: any) => {
            if (response.status === 200) {
              setResult({
                discountEligible: response.data.data.discountEligible,
                isPermanentDiscount: response.data.data.isPermanentDiscount,
                successMessage: response.data.message.value,
              });
              setShow(true);

              setTimeout(() => {
                setResult((pre: any) => ({
                  ...pre,
                  successMessage: '',
                }));
              }, 2000);
            }
          })
          .catch((error: any) => {
            console.error('ERROR', error);
            setResult({
              errorMessage: error.response.data.message.value,
            });
            // setToast({ message: error.response.data.message.value, type: 'error' });
            // handleClick();
          });
      }
    }
  };

  const permanentUser = () => {
    // setResult((ps: any) => ({ ...ps, errorMessage: '' }));
    // handleDiscountCode();
    // if (!result.errorMessage) {
    const permanentDiscount = privateAxios.post(
      `plan/coupon`,
      {
        isPermanentDiscount: true,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    permanentDiscount
      .then((response: any) => {
        if (response.status === 200) {
          setResult({
            discountEligible: response.data.data.discountEligible,
            isPermanentDiscount: response.data.data.isPermanentDiscount,
            successMessage: response.data.message.value,
          });

          setTimeout(() => {
            setResult((pre: any) => ({
              ...pre,
              successMessage: '',
            }));
            dispatch(userPM(response.data.data.isPayment));
            dispatch(userDC(response.data.data.isPermanent));
            navigate('/premium/sign-up');
          }, 2000);
        }
      })
      .catch((error: any) => {
        console.error('ERROR', error);
        setResult({
          errorMessage: error.response.data.message.value,
        });
        // setToast({ message: error.response.data.message.value, type: 'error' });
        // handleClick();
      });
    // }
  };
  useEffect(() => {
    if (discount) {
      handleDiscountCode();
      // if (discount.startsWith('PER-')) {

      //   permanentUser();
      // }
      // if (discount.length === 10) {

      //   handleDiscountCode();
      // }
    }
  }, [discount]);
  // const handleInputChange = (e: any) => {
  //   setDiscount(
  //     e.target.value
  //       .replace(/^\s+/, '') // Remove leading whitespace
  //       .replace(/\s+/g, ' ') // Collapse multiple whitespaces to a single space
  //       .replace(/^[^a-zA-Z0-9@.]+/, '') // Allow digits as well at the beginning
  //   );
  //   if (e.target.value.length === 10) {
  //     dispatch(userDisElgble(true));
  //   } else {
  //     dispatch(userDisElgble(false));
  //   }
  // };

  const handleOnlyInputChange = (e: any) => {
    setDiscount(
      e.target.value
        .replace(/^\s+/, '') // Remove leading whitespace
        .replace(/\s+/g, ' ') // Collapse multiple whitespaces to a single space
        .replace(/^[^a-zA-Z0-9@.]+/, '') // Allow digits as well at the beginning
    );
    setResult({
      errorMessage: '',
    });
    if (e.target.value.length === 10) {
      dispatch(userDisElgble(true));
    } else {
      dispatch(userDisElgble(false));
    }
  };

  const handleInitialDiscountSubmit = () => {
    if (selectedValue === 'yes') {
      if (discount === '') {
        setResult({
          errorMessage: 'Discount code is required',
        });
        return;
      }

      const withDiscountCode = privateAxios.post(`/discount/withdiscount`);
      withDiscountCode
        .then((response: any) => {
          setToast({ message: response.data.message.value, type: 'success' });
          if (props.userType) {
            navigate('/premium/sign-up');
          } else {
            // dispatch(isLoggedIn(false));
            // dispatch(backHandle(false));
            // dispatch(userId(''));
            // dispatch(customerID(''));
            // dispatch(userType(false));
            // dispatch(userPM(false));
            // dispatch(userAccessToken({ accessToken: '' }));
            // dispatch(userRefreshToken({ refreshToken: '' }));
            // dispatch(userDC(false));
            // dispatch(userDisElgble(false));
            // // dispatch(logOut());
            // localStorage.removeItem('center');
            // localStorage.clear();
            dispatch(userType(true));
            navigate('/');
          }
        })
        .catch((error: any) => {
          setToast({ message: error.response.data.message.value, type: 'error' });
          handleClick();
        });
    } else {
      const withNoDiscountCode = privateAxios.post(`/discount/nodiscount`);
      withNoDiscountCode
        .then((response: any) => {
          // setToast({ message: response.data.message.value, type: 'success' });
          if (response.data.message.value === 'Success!') {
            if (props.userType) {
              navigate('/premium/sign-up');
            } else {
              // dispatch(isLoggedIn(false));
              // dispatch(backHandle(false));
              // dispatch(userId(''));
              // dispatch(customerID(''));
              // dispatch(userStepStatus({ status: '' }));
              dispatch(userType(true));
              // dispatch(userPM(false));
              // dispatch(userAccessToken({ accessToken: '' }));
              // dispatch(userRefreshToken({ refreshToken: '' }));
              // dispatch(userDC(false));
              // dispatch(userDisElgble(false));
              // // dispatch(logOut());
              // localStorage.removeItem('center');
              // localStorage.clear();
              navigate('/');
            }
          }
        })
        .catch((error: any) => {
          console.error(error.response.data.message.value);
        });
    }
  };

  return (
    <>
      {props.status === 'Registration' || !props.userType ? (
        <>
          <div className="discount_container">
            <Paper className="chosePayDiscount">
              <Grid sx={{ overflowX: 'hidden !important', overflowY: 'hidden !important' }}>
                <Box className="disCode">
                  <Typography
                    style={{ color: '#344054', fontSize: '24px', fontWeight: 500, margin: '10px 0', textAlign: 'center' }}>
                    You have a discount code ?
                  </Typography>
                  <FormControl component="fieldset">
                    <RadioGroup name="haveRadioGroup" value={selectedValue} onChange={handleRadioChange}>
                      <FormControlLabel value="yes" control={<Radio />} label="Yes, I have" className="dis_radio" />
                      <TextField
                        className="formFields dis_value_st"
                        type="text"
                        name="discount"
                        placeholder="Enter discount code here"
                        fullWidth
                        inputProps={{
                          maxLength: 10,
                        }}
                        value={discount}
                        onChange={handleOnlyInputChange}
                        disabled={selectedValue === 'yes' ? false : true}
                      />
                      {result.successMessage && (
                        <Typography
                          sx={{ color: '#2CB512', mt: 1, ml: '40px', fontFamily: "'Onest', sans-serif !important" }}>
                          {result.successMessage}
                        </Typography>
                      )}
                      {result.errorMessage && (
                        <Typography sx={{ color: 'red', mt: 1, ml: '40px', fontFamily: "'Onest', sans-serif !important" }}>
                          {result.errorMessage}
                        </Typography>
                      )}

                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No, I don't have"
                        className="dis_radio"
                        style={{ marginTop: '20px' }}
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
              <Grid className="canButton">
                <Button
                  className="common-button-green"
                  type="submit"
                  variant="contained"
                  onClick={() => handleInitialDiscountSubmit()}
                  sx={{
                    width: '50%',
                    height: '44px',
                    '&:hover': {
                      background: 'var(--Primary, #2CB512)',
                    },
                  }}
                  disabled={
                    selectedValue === 'no' ? false : selectedValue === 'yes' && discount.length === 10 && show ? false : true
                  }>
                  Next
                </Button>
              </Grid>
            </Paper>
          </div>
        </>
      ) : (
        <>
          <Paper className="chosePay">
            <Typography
              sx={{ color: '#2CB512', fontSize: '18px', fontWeight: 600, fontFamily: "'Onest', sans-serif !important" }}>
              Choose your payment option
            </Typography>
            <Typography
              sx={{
                color: '#73787E',
                fontSize: '16px',
                fontWeight: 400,
                marginTop: '10px',
                paragraph: '16px',
                lineHeight: '24px',
                fontFamily: "'Onest', sans-serif !important",
              }}>
              We offer safe and secure checkout via debit or credit card or through PayPal.
            </Typography>
            <Grid sx={{ padding: '20px 10px', overflowX: 'hidden !important', overflowY: 'hidden !important' }}>
              <RadioGroup value={selectedPayment} onChange={handlePaymentChange} sx={{ marginTop: '10px' }}>
                <FormControlLabel
                  className="paymentPay"
                  value="stripe"
                  control={<Radio />}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    border: selectedPayment === 'stripe' ? '1px solid #2CB512' : '1px solid #EAECF0',
                    borderRadius: '10px',
                    padding: '10px',
                    '&:hover': {
                      backgroundColor: '#f7f7f7', // Background color on hover
                    },
                  }}
                  labelPlacement="start"
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {/* <img src={creditdebitcard} alt="Stripe" style={{ width: '150px', marginRight: '10px' }} /> */}
                      <img src={cards} alt="Stripe" style={{ marginRight: '10px' }} />{' '}
                      <Typography
                        sx={{
                          color: '#2CB512',
                          fontWeight: 600,
                          fontSize: '18px',
                          fontFamily: "'Onest', sans-serif !important",
                        }}>
                        Credit/Debit Card
                      </Typography>
                    </Box>
                  }
                />
                <FormControlLabel
                  className="paymentPay"
                  value="paypal"
                  control={<Radio />}
                  labelPlacement="start"
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    border: selectedPayment === 'paypal' ? '1px solid #2CB512' : '1px solid #EAECF0',
                    borderRadius: '10px',
                    padding: '10px',
                    '&:hover': {
                      backgroundColor: '#f7f7f7', // Background color on hover
                    },
                  }}
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <img src={paypal} alt="PayPal" style={{ width: '150px', marginRight: '10px' }} />
                    </Box>
                  }
                />
                <FormControlLabel
                  className="paymentPay"
                  value="discount"
                  control={<Radio />}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    border: selectedPayment === 'discount' ? '1px solid #2CB512' : '1px solid #EAECF0',
                    borderRadius: '10px',
                    padding: '10px',
                    '&:hover': {
                      backgroundColor: '#f7f7f7', // Background color on hover
                    },
                  }}
                  labelPlacement="start"
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography
                        sx={{
                          color: '#344054',
                          fontWeight: 600,
                          fontSize: '18px',
                          fontFamily: "'Onest', sans-serif !important",
                        }}>
                        Have a discount code?
                      </Typography>
                    </Box>
                  }
                />
              </RadioGroup>
              {selectedPayment === 'discount' && (
                <Box className="disCode" sx={{ margin: '10px 0 0 0px' }}>
                  <TextField
                    className="formFields"
                    sx={{ mt: 1 }}
                    type="text"
                    name="discount"
                    placeholder="Enter discount code here"
                    fullWidth
                    inputProps={{
                      maxLength: 10,
                    }}
                    onChange={handleOnlyInputChange}
                  />
                  {result.successMessage && (
                    <Typography sx={{ color: '#2CB512', mt: 1, fontFamily: "'Onest', sans-serif !important" }}>
                      {result.successMessage}
                    </Typography>
                  )}
                  {result.errorMessage && (
                    <Typography sx={{ color: 'red', mt: 1, fontFamily: "'Onest', sans-serif !important" }}>
                      {result.errorMessage}
                    </Typography>
                  )}
                </Box>
              )}
            </Grid>
            <Grid className="canButton">
              <Button
                className="common-button-white"
                type="submit"
                variant="contained"
                onClick={onCloseChoose}
                sx={{
                  width: '48%',
                  height: '44px',
                  margin: '0 1%',
                  '&:hover': {
                    color: 'var(--Primary, #2CB512)',
                    backgroundColor: '#fff',
                  },
                }}>
                Cancel
              </Button>
              <Button
                className="common-button-green"
                type="submit"
                variant="contained"
                onClick={() => handlePaymentSubmit()}
                sx={{
                  width: '48%',
                  height: '44px',
                  margin: '0 1%',
                  '&:hover': {
                    background: 'var(--Primary, #2CB512)',
                  },
                }}
                disabled={
                  selectedPayment !== 'discount'
                    ? false
                    : selectedPayment === 'discount' && discount.length === 10 && show
                    ? false
                    : true
                }>
                Next
              </Button>
            </Grid>
          </Paper>
        </>
      )}

      <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
    </>
  );
};

function mapDispatchToProps(state: any) {
  return {
    CID: state.CID,
    status: state.status,
    userType: state.userType,
  };
}

export default connect(mapDispatchToProps)(ChoosePayment);
